













import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import moment from 'moment'

@Component
export default class DatePickerBase extends Vue {
  @Prop({ default: '' })
  date!: string

  @Prop({ default: false })
  clearButton!: boolean

  private currentDate: Date | null = this.stringToDate(this.date)

  // date propsのウォッチャー
  @Watch('date')
  onDateChanged(newDate: string, oldDate: string) {
    this.currentDate = this.stringToDate(newDate)
  }

  get selectedDate(): Date | null {
    return this.currentDate
  }

  set selectedDate(value) {
    this.currentDate = value
    this.$emit('input', this.dateToString(value))
  }

  private stringToDate(date: string): any {
    if (date === '') return null
    return new Date(date as string)
  }

  private dateToString(date: Date | null): string {
    if (date === null) return ''
    return moment(date).format('YYYY/MM/DD')
  }

  private clearDate(): void {
    this.selectedDate = null
  }
}
