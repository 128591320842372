





























import { Component, Vue } from 'vue-property-decorator'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import RadioGroup from '@/components/molecules/RadioGroup.vue'
import DatePickerBase from '@/components/atoms/DatePickerBase.vue'
import TableBase from '@/components/atoms/TableBase1110.vue'
import moment from 'moment'

@Component({
  components: {
    ButtonBase,
    RadioGroup,
    DatePickerBase,
    TableBase,
  },
})
export default class CsvDownload extends Vue {
  private academyId = Vue.prototype.$cookies.get('dataGdls').academyId

  private currentPage = 1
  private branchesCount = 0
  private branchesTablePerPage = 30

  private branchesTableItems: { id: { value: number; label: string }; branchCode: string; branchName: string }[] = []
  private branchesTableRadios = ['id']
  private checkedValue: number | null = null
  private branchesTableFields = [
    { key: 'id', label: '' },
    { key: 'branchCode', label: '教室コード' },
    { key: 'branchName', label: '教室名' },
  ]

  private from = ''
  private to = ''

  private csvTypeRadioItems = [
    { label: '目標達成状況', value: 1 },
    { label: '学習状況', value: 2 },
    { label: '理解度', value: 3 },
  ]
  private csvTypeValue = 1

  private isProcessing = false

  private get colortype(): string {
    return this.from && this.to ? 'blue' : 'pointer-events-none'
  }

  private onBranchChange(value: number): void {
    this.checkedValue = value
  }

  private async mounted() {
    // TODO: 教室選択機能有無の判断
    //await this.loadBranches()
  }

  private async paginate(page: number) {
    this.currentPage = page
    await this.loadBranches()
  }

  private async loadBranches() {
    Vue.prototype.$loading.start()
    const params = {
      academyId: this.academyId,
      limit: this.branchesTablePerPage,
      offset: this.branchesTablePerPage * (this.currentPage - 1),
    }

    await Vue.prototype.$http.httpWithToken.get('/branches', { params: params }).then((res: any) => {
      this.branchesTableItems = res.data.branches.map((branch: any) => {
        return {
          id: { value: branch.branchId, label: '' },
          branchCode: branch.branchCode,
          branchName: branch.branchName,
        }
      })
      this.branchesCount = res.data.count
      this.checkedValue = res.data.branches[0].branchId
    })
    Vue.prototype.$loading.complete()
  }

  private async downloadCsv() {
    const diff = moment(this.to, 'YYYY-MM-DD').diff(moment(this.from, 'YYYY-MM-DD'), 'months')
    if (diff >= 3) {
      alert('期間指定は3ヶ月以内としてください。')
      return
    }

    if (this.isProcessing) return
    Vue.prototype.$loading.start()
    this.isProcessing = true

    let apiPath = ''
    switch (this.csvTypeValue) {
      case 1:
        apiPath = '/v3/studentGoals/csvOutput'
        break
      case 2:
        apiPath = '/history/resultSummariesCsv'
        break
      case 3:
        apiPath = '/history/resultDrillsCsv'
        break
    }
    // TODO: 教室選択機能有無の判断
    //const params = { branchId: this.checkedValue, from: this.from, to: this.to }
    const params = { academyId: this.academyId, from: this.from, to: this.to }

    await Vue.prototype.$http.httpWithToken
      .get(apiPath, {
        responseType: 'blob',
        dataType: 'binary',
        headers: {
          Accept: 'application/zip',
        },
        params: params,
      })
      .then((res: any) => {
        const blob = new Blob([res.data], { type: 'application/zip' })
        const a = document.createElement('a')
        a.href = window.URL.createObjectURL(blob)
        a.download = this.getFilename(res.headers['content-disposition'])
        a.click()
      })
      .catch(() => {
        alert('エラーが発生しました')
      })
      .finally(() => {
        this.isProcessing = false
        Vue.prototype.$loading.complete()
      })
  }

  /**
   * Content-Dispositionヘッダーからfilenameを取得する
   */
  private getFilename(contentDisposition: string): string {
    const filenameRegex = /filename\*=['"]?(?:UTF-\d['"]*)?([^;\r\n"']*)['"]?;?/
    const matches = filenameRegex.exec(contentDisposition)
    if (matches != null && matches[1]) {
      return decodeURI(matches[1].replace(/['"]/g, '').replace('utf-8', ''))
    } else {
      alert('ファイル名の取得に失敗しました')
      return 'file_name_error.zip'
    }
  }
}
